$(document).ready(function() {

  /*
   * Swiper slider for product details
   */
  var productSlider = new Swiper('.product-slider', {
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    spaceBetween: 20,
    slidesPerView: 1,
    loop: true,
    observer: true,
    observeParents: true,
    loopedSlides: $('.product-slider > .swiper-wrapper > .swiper-slide').length
  });

  var productThumbs = new Swiper('.product-thumbs', {
    spaceBetween: 5,
    slidesPerView: 4,
    slideToClickedSlide: true,
    direction: 'vertical',
    touchRatio: 0.2,
    centeredSlides: true,
    loop: true,
    observer: true,
    observeParents: true,
    loopedSlides: $('.product-thumbs > .swiper-wrapper > .swiper-slide').length
  });

  productSlider.params.control = productThumbs;
  productThumbs.params.control = productSlider;
});
